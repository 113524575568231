<template>
  <v-card outlined>
    <v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4 align-center">
      <v-row no-gutters align="center">
      <v-card-subtitle class="pa-0 col-12 col-md-6">
        <v-icon small>mdi-account-group</v-icon>&nbsp;Comisionistas
      </v-card-subtitle>
      <v-spacer />
      <v-text-field
        filled
        hide-details
        append-icon="mdi-magnify"
        dense
        v-model="search"
      ></v-text-field>
      </v-row>
    </v-sheet>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="comisionistas"
      :search="search"
    >
    </v-data-table>
  </v-card>
</template>

<script>
// import axios from "redaxios";
import { parseDate, processToken } from "@/utils/index.js";
export default {
  data() {
    return {
      search: null,
      comisionistas: [],
      headers: [
        {text: 'Contrato'},
        {text: '%Beneficio'},
        {text: 'Importe Base'},
        {text: 'Importe Total'},
      ],
    };
  },
  mounted() {
    let str = this.$store.getters.getDatosEmpresa;

    const { IdUsuario, IdAgente } = str.iss;
    const IdEmpresa = this.$store.getters.getIdEmpresa

    var ourDate = new Date();

    var pastDate = ourDate.getDate() - 14;
    ourDate.setDate(pastDate);

    let fecha = parseDate(ourDate, false, false).split(" ").reverse().join("-");

    axios({
      method: "GET",
      url: `${process.env.VUE_APP_API_URL}/Agente/ResumenUltimasComisiones/?TablasMaestras_FinderFilterResumenDatosAgenteAPIDTO={IdEmpresa:${IdEmpresa},IdUsuario:${IdUsuario},IdAgente:${IdAgente},FechaDesde:'${fecha}'}`,
    }).then((res) => {
      this.comisionistas = processToken(res.data).iss;
    });
  },
};
</script>

<style>
</style>